export const eventList = [
  {
    id: "678",
    coverImage: "https://via.placeholder.com/1600x900.png?text=Cover+Picture",
    date: "2023/05/09 20:00",
    name: "CAMILA Y RAMÓN",
    type: "Boda",
    backCover: {
      image: "https://via.placeholder.com/1600x900.png?text=BackCover+Picture",
      quote: "We want you to be part of this special event",
    },
    venues: [
      {
        title: "Parroquia Jesús El Buen Pastor",
        image: "https://via.placeholder.com/150.png?text=Venue1+Picture",
        address: "Av. De las Arboledas, Satélite, Cd. Monterrey, N.L.",
        category: "Misa",
        date: "09 Mayo 2023",
        time: "18:00 hrs",
      },
      {
        title: "Las Pampas Eventos",
        image: "https://via.placeholder.com/150.png?text=Venue2+Picture",
        address: "Ave. Eugenio Garza Sada #2134, Monterrey, N.L.",
        category: "Recepción",
        date: "09 Mayo 2023",
        time: "20:00 hrs",
      },
    ],
    dressCode: {
      title: "Código de Vestimenta",
      description: "Elegante/Formal",
    },
    giftList: {
      title: "Mesa de Regalos",
    },
    carouselImages: [
      "https://via.placeholder.com/1600x900.png?text=Carousel1+Picture",
      "https://via.placeholder.com/1600x900.png?text=Carousel2+Picture",
      "https://via.placeholder.com/1600x900.png?text=Carousel3+Picture",
    ],
    waConfirmation: {
      waNumber: 521111111111,
      message: "Confirmo mi asistencia para el evento de Camila y Ramón",
      receiver: "Alma",
      title: "Confirmar asistencia",
      instructions: "Favor de confirmar asistencia un mes antes del evento",
      note: "Por favor indicanos si tienes alguna restricción alimentaria",
    },
    closing: {
      image: "https://via.placeholder.com/1600x900.png?text=Closing+Picture",
      quote: "Hope we can see you there",
      title: "Camila y Ramón",
    },
    audio: {
      url: "http://hola.com",
    },
  },
];
