import React from "react";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carousel.scss";

export interface CarouselProps {
  autoPlay?: boolean;
  interval?: number;
  showArrows?: boolean;
  showIndicators?: boolean;
  showThumbs?: boolean;
  images: Array<string>;
}
export const Carousel: React.FC<CarouselProps> = ({
  autoPlay = true,
  interval = 3000,
  showArrows = true,
  showIndicators = true,
  showThumbs = false,
  images,
}) => {
  return (
    <div className="carousel_component">
      <ResponsiveCarousel
        autoPlay={autoPlay}
        interval={interval}
        showArrows={showArrows}
        showIndicators={showIndicators}
        showThumbs={showThumbs}
        preventMovementUntilSwipeScrollTolerance={true}
      >
        {images.map((image, index) => {
          return (
            <div key={index}>
              <img src={image} alt={image} />
            </div>
          );
        })}
      </ResponsiveCarousel>
    </div>
  );
};
