import React from "react";
import { Link } from "react-router-dom";
import { eventList } from "../../mocks/mock_events";

import "./Home.scss";

const Home: React.FC = () => {
  return (
    <div className="home">
      <h1>Mari invitaciones digitales</h1>
      <p>
        ¡Bienvenidos a nuestra página! Visita nuestras muestras, puedes
        mandarnos un correo para pedir cualquier información a{" "}
        <a href="mailto:mari.invitaciones@gmail.com">
          mari.invitaciones@gmail.com
        </a>
      </p>
      <p>Eventos muestra: </p>
      <ul>
        {eventList.map((event) => {
          return (
            <li key={event.id}>
              <Link to={`sample/${event.id}`}>{event.name}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Home;
