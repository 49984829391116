import React from "react";
import { GiAmpleDress, GiBowTieRibbon, GiLargeDress } from "react-icons/gi";
import { FaUserTie } from "react-icons/fa";

import "./DressCode.scss";

export interface DressCodeProps {
  title: string;
  description: string;
}

export const DressCode: React.FC<DressCodeProps> = ({ title, description }) => {
  return (
    <div className="component_dressCode">
      <h2>{title}</h2>
      <div className="component_dressCode__list">
        <div className="component_dressCode__list__element">
          <GiAmpleDress />
          <GiBowTieRibbon />
        </div>
        <div className="component_dressCode__list__element">
          <GiLargeDress />
          <FaUserTie />
        </div>
      </div>
      <div className="component_dressCode__description">{description}</div>
    </div>
  );
};
