import React from "react";

import "./Image.scss";

export interface ImageProps {
  name: string;
}

export const Image: React.FC<ImageProps> = ({ name }) => {
  try {
    if (!name) return null;
    return (
      <div className="image_component">
        <img src={name} alt={name} />
      </div>
    );
  } catch (error) {
    console.log(`Image with name "${name}" does not exist`);
    return null;
  }
};
