import React from "react";
import { AudioPlayer } from "../AudioPlayer";
import { AudioPlayerProps } from "../AudioPlayer/AudioPlayer";
import { Carousel } from "../Carousel";
import { Cover } from "../Cover";
import { BackCover } from "../BackCover";
import { BackCoverProps } from "../BackCover/BackCover";
import { Closing } from "../Closing";
import { ClosingProps } from "../Closing/Closing";
import { DressCode, DressCodeProps } from "../DressCode/DressCode";
import { VenueList } from "../VenueList";
import { VenueCardProps } from "../VenueCard/VenueCard";
import { WAConfirmation } from "../WAConfirmation";
import { WAConfirmationProps } from "../WAConfirmation/WAConfirmation";
import { GiftList, GiftListProps } from "../GiftList/GiftList";

import "./EventDetails.scss";
export interface EventDetailsProps {
  id: string;
  coverImage: string;
  date: string;
  name: string;
  type: string;
  backCover: BackCoverProps;
  venues: Array<VenueCardProps>;
  dressCode: DressCodeProps;
  giftList: GiftListProps;
  carouselImages: Array<string>;
  waConfirmation: WAConfirmationProps;
  closing: ClosingProps;
  audio: AudioPlayerProps;
}

export const EventDetails: React.FC<EventDetailsProps> = ({
  coverImage,
  date,
  name,
  type,
  venues,
  backCover,
  carouselImages,
  waConfirmation,
  closing,
  dressCode,
  giftList,
  audio,
}) => {
  const getDate = (date: string) => {
    return new Date(date);
  };

  return (
    <div className="component__event-details">
      <Cover
        eventDate={getDate(date)}
        eventName={name}
        eventType={type}
        image={coverImage}
        includeCountDown
      />
      <BackCover image={backCover.image} quote={backCover.quote} />
      <VenueList title="Dónde y cuándo" venues={venues} />
      <DressCode title={dressCode.title} description={dressCode.description} />
      <GiftList title={giftList.title} />
      <WAConfirmation
        instructions={waConfirmation.instructions}
        waNumber={waConfirmation.waNumber}
        message={waConfirmation.message}
        note={waConfirmation.note}
        receiver={waConfirmation.receiver}
        title={waConfirmation.title}
      />
      <Carousel images={carouselImages} />
      <Closing
        image={closing.image}
        quote={closing.quote}
        title={closing.title}
      />
      <AudioPlayer url={audio.url} />
    </div>
  );
};
