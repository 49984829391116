import React from "react";
import { VenueCard } from "../VenueCard";
import { VenueCardProps } from "../VenueCard/VenueCard";

import "./VenueList.scss";

export interface VenueListProps {
  title: string;
  venues: Array<VenueCardProps>;
}

export const VenueList: React.FC<VenueListProps> = ({ title, venues }) => {
  return (
    <div className="component__venue-list">
      <h1>{title}</h1>
      <div className="venues">
        {venues.map((venue, index) => {
          return <VenueCard {...venue} key={index} />;
        })}
      </div>
    </div>
  );
};
