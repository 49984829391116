import React from "react";
import { Card, Image } from "semantic-ui-react";
import { Map } from "../Map";

import "./VenueCard.scss";

export interface VenueCardProps {
  title: string;
  image: string;
  address: string;
  category: string;
  date: string;
  time: string;
  includeMap?: boolean;
  mapUrl?: string;
}

export const VenueCard: React.FC<VenueCardProps> = ({
  title,
  image,
  address,
  category,
  date,
  time,
  includeMap = false,
  mapUrl,
}: VenueCardProps) => {
  return (
    <div className="component__venue-card">
      <Card>
        <Card.Header>{category}</Card.Header>
        <Image src={image} alt={image} wrapped ui={false} />
        <Card.Description>
          <p>
            <b>{title}</b>
          </p>
          <p>Dónde: {address}</p>
          <p>
            Cuándo: {date}, {time}
          </p>
          {includeMap && <Map mapUrl={mapUrl!} mapTitle={title} />}
        </Card.Description>
      </Card>
    </div>
  );
};
