import React from "react";
import { FaEnvelope } from "react-icons/fa";

import "./GiftList.scss";

export interface GiftListProps {
  title: string;
}

export const GiftList: React.FC<GiftListProps> = ({ title }) => {
  return (
    <div className="component_giftList">
      <h2>{title}</h2>
      <div className="component_giftList__list">
        <div className="component_giftList__list__element">
          <FaEnvelope />
        </div>
      </div>
    </div>
  );
};
