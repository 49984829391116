import React from "react";
import { EventDetails } from "../../components/EventDetails";
import coverPicture from "../../assets/SaraMaye/Cover.jpeg";
import backCoverPicture from "../../assets/SaraMaye/BackCover.jpeg";
import carouselPicture1 from "../../assets/SaraMaye/Carousel1.jpeg";
import carouselPicture2 from "../../assets/SaraMaye/Carousel2.jpeg";
import carouselPicture3 from "../../assets/SaraMaye/Carousel3.jpeg";
import carouselPicture4 from "../../assets/SaraMaye/Carousel4.jpeg";
import carouselPicture5 from "../../assets/SaraMaye/Carousel5.jpeg";
import carouselPicture6 from "../../assets/SaraMaye/Carousel6.jpeg";
import carouselPicture7 from "../../assets/SaraMaye/Carousel7.jpeg";
import carouselPicture8 from "../../assets/SaraMaye/Carousel8.jpeg";
import carouselPicture9 from "../../assets/SaraMaye/Carousel9.jpeg";
import carouselPicture10 from "../../assets/SaraMaye/Carousel10.jpeg";
import carouselPicture11 from "../../assets/SaraMaye/Carousel11.jpeg";
import carouselPicture12 from "../../assets/SaraMaye/Carousel12.jpeg";
import carouselPicture13 from "../../assets/SaraMaye/Carousel13.jpeg";
import carouselPicture14 from "../../assets/SaraMaye/Carousel14.jpeg";
import carouselPicture15 from "../../assets/SaraMaye/Carousel15.jpeg";
import carouselPicture16 from "../../assets/SaraMaye/Carousel16.jpeg";
import carouselPicture17 from "../../assets/SaraMaye/Carousel17.jpeg";
import carouselPicture18 from "../../assets/SaraMaye/Carousel18.jpeg";
import carouselPicture19 from "../../assets/SaraMaye/Carousel19.jpeg";
import carouselPicture20 from "../../assets/SaraMaye/Carousel20.jpeg";
import carouselPicture21 from "../../assets/SaraMaye/Carousel21.jpeg";
import carouselPicture22 from "../../assets/SaraMaye/Carousel22.jpeg";
import carouselPicture23 from "../../assets/SaraMaye/Carousel23.jpeg";
import carouselPicture24 from "../../assets/SaraMaye/Carousel24.jpeg";
import carouselPicture25 from "../../assets/SaraMaye/Carousel25.jpeg";
import carouselPicture26 from "../../assets/SaraMaye/Carousel26.jpeg";
import carouselPicture27 from "../../assets/SaraMaye/Carousel27.jpeg";
import closingPicture from "../../assets/SaraMaye/Closing.jpeg";
import churchPicture from "../../assets/SaraMaye/Church.png";
import venuePicture from "../../assets/SaraMaye/Venue.png";
import sugarSong from "../../assets/SaraMaye/sugar.mp3";

import "./SaraYMaye.scss";

const SaraYMayeContainer: React.FC = () => {
  const event = {
    id: "234",
    coverImage: coverPicture,
    date: "2022/07/15 20:00",
    name: "SARA Y MAYE",
    type: "Mis XV años",
    backCover: {
      image: backCoverPicture,
      quote:
        "Porque eres parte de mi vida y has acompañado mi camino, quiero que seas parte de este momento tan importante para mi.",
    },
    venues: [
      {
        title: "Parroquia Cristo Buen Pastor",
        image: churchPicture,
        address: "Av. De las Arboledas, Fresnos II, Cd. Apodaca, N.L.",
        category: "Misa",
        date: "16 Julio 2022",
        time: "18:00 hrs",
        includeMap: true,
        mapUrl:
          "!1m18!1m12!1m3!1d3593.0147186964555!2d-100.25427548451593!3d25.770077383632994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662eb56f92f28e5%3A0xf4cadf85d6eec334!2sParroquia%20Cristo%20Buen%20Pastor!5e0!3m2!1ses!2smx!4v1653260993658!5m2!1ses!2smx",
      },
      {
        title: "Getzaman Eventos",
        image: venuePicture,
        address: "Carretera Mezquital Santa Rosa #213, Apodaca, N.L.",
        category: "Recepción",
        date: "16 Julio 2022",
        time: "20:00 hrs",
        includeMap: true,
        mapUrl:
          "!1m18!1m12!1m3!1d3592.0469901849615!2d-100.2265964845154!3d25.8020237836184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662eb698520d305%3A0x157595b60ebab77f!2sGetzaman%20Eventos!5e0!3m2!1ses!2smx!4v1653274197372!5m2!1ses!2smx",
      },
    ],
    dressCode: {
      title: "Código de Vestimenta",
      description: "Elegante/Formal",
    },
    giftList: {
      title: "Mesa de Regalos",
    },
    carouselImages: [
      carouselPicture1,
      carouselPicture2,
      carouselPicture3,
      carouselPicture4,
      carouselPicture5,
      carouselPicture6,
      carouselPicture7,
      carouselPicture8,
      carouselPicture9,
      carouselPicture10,
      carouselPicture11,
      carouselPicture12,
      carouselPicture13,
      carouselPicture14,
      carouselPicture15,
      carouselPicture16,
      carouselPicture17,
      carouselPicture18,
      carouselPicture19,
      carouselPicture20,
      carouselPicture21,
      carouselPicture22,
      carouselPicture23,
      carouselPicture24,
      carouselPicture25,
      carouselPicture26,
      carouselPicture27,
    ],
    waConfirmation: {
      instructions: "Favor de confirmar asistencia antes del 1 de Julio",
      waNumber: 528127325102,
      message: "Confirmo mi asistencia para el evento de Sara Y Maye",
      note: "No te olvides de dejar un bonito mensaje en el libro de firmas",
      receiver: "Ale",
      title: "Confirmar Asistencia",
    },
    closing: {
      image: closingPicture,
      quote: "¡Te esperamos para celebrar junto a nuestra familia!",
      title: "SARA Y MAYE",
    },
    audio: {
      url: sugarSong,
    },
  };

  return event ? (
    <EventDetails
      id={event.id}
      coverImage={event.coverImage}
      date={event.date}
      name={event.name}
      type={event.type}
      backCover={event.backCover}
      venues={event.venues}
      carouselImages={event.carouselImages}
      waConfirmation={event.waConfirmation}
      closing={event.closing}
      dressCode={event.dressCode}
      giftList={event.giftList}
      audio={event.audio}
    />
  ) : null;
};

export default SaraYMayeContainer;
