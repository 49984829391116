type CountDownTextType = {
  [key: string]: string;
};

export const intervalText: CountDownTextType = {
  days: "Días",
  hours: "Horas",
  minutes: "Minutos",
  seconds: "Segundos",
};
