import React from "react";
import { Image } from "../Image";

import "./BackCover.scss";

export interface BackCoverProps {
  image: string;
  quote: string;
}

export const BackCover: React.FC<BackCoverProps> = ({ image, quote }) => {
  return (
    <div className="backCover_component">
      <Image name={image} />
      <div className="backCover_component__quote">{quote}</div>
    </div>
  );
};
