import React from "react";
import { Image } from "../Image";

import "./Closing.scss";

export interface ClosingProps {
  image: string;
  quote: string;
  title: string;
}

export const Closing: React.FC<ClosingProps> = ({ image, quote, title }) => {
  return (
    <div className="closing_component">
      <Image name={image} />
      <div className="closing_component__text">
        <h2>{title}</h2>
        <div className="closing_component__quote">{quote}</div>
      </div>
    </div>
  );
};
