import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";

import "./Map.scss";

export interface MapProps {
  mapUrl: string;
  mapTitle: string;
}

export const Map: React.FC<MapProps> = ({ mapUrl, mapTitle }: MapProps) => {
  const [displayMap, setDisplayMap] = useState(false);
  const handleOpen = () => setDisplayMap(true);
  const handleClose = () => setDisplayMap(false);

  return (
    <div className="component__map">
      <button className="component__map-button" onClick={handleOpen}>
        Mostrar Mapa
      </button>
      <Modal open={displayMap} onClose={handleClose}>
        <div className="component__map_modal">
          <h3>{mapTitle}</h3>
          <div className="component__map_modal_iframe">
            <iframe
              src={`https://www.google.com/maps/embed?pb=${mapUrl}`}
              width="100%"
              height="95%"
              loading="lazy"
              title="my title"
            ></iframe>
            <button
              className="component__map-button--close"
              onClick={handleClose}
            >
              Cerrar Mapa
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
