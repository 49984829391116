import React, { useEffect, useState } from "react";
import { intervalText } from "../../utils/utils";

import "./CountDown.scss";

export interface CountDownProps {
  eventDate: Date;
}

type CountDownType = {
  [key: string]: number;
};

export const CountDown: React.FC<CountDownProps> = ({ eventDate }) => {
  const castIntervalTime = (intervalTime: number, interval: string) => {
    if (interval === "seconds" && intervalTime < 10) {
      return "0" + String(intervalTime);
    }
    return intervalTime;
  };

  const calculateTimeLeft = (): CountDownType => {
    const difference = +eventDate - +new Date();
    const timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft.days = Math.floor(difference / (1000 * 60 * 60 * 24));
      timeLeft.hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      timeLeft.minutes = Math.floor((difference / 1000 / 60) % 60);
      timeLeft.seconds = Math.floor((difference / 1000) % 60);
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="count_down_component">
      {Object.keys(timeLeft).map((interval, index) => {
        return (
          <div className="count_down_component__interval" key={index}>
            <div className="count_down_component__interval__number">
              {castIntervalTime(timeLeft[interval], interval)}
            </div>
            <div className="count_down_component__interval__text">
              {intervalText[interval]}
            </div>
          </div>
        );
      })}
    </div>
  );
};
