import React from "react";
import { CountDown } from "../CountDown";
import { Image } from "../Image";

import "./Cover.scss";

export interface CoverProps {
  eventDate: Date;
  eventName: string;
  eventType: string;
  image: string;
  includeCountDown?: boolean;
}

export const Cover: React.FC<CoverProps> = ({
  eventDate,
  eventName,
  eventType,
  image,
  includeCountDown = false,
}) => {
  return (
    <div className="cover_component">
      <Image name={image} />
      <div className="cover_component__information">
        <h2>{eventName}</h2>
        <h3>{eventType}</h3>
        {includeCountDown && <CountDown eventDate={eventDate} />}
      </div>
    </div>
  );
};
