import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import EventDetailsContainer from "./pages/eventDetails/EventDetailsContainer";
import SaraYMayeContainer from "./pages/SaraYMaye/SaraYMayeContainer";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path={"/event/saraymayexv"}>
            <SaraYMayeContainer />
          </Route>
          <Route path={"/sample/:eventId"}>
            <EventDetailsContainer />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
