import React from "react";
import { useParams } from "react-router-dom";
import { EventDetails } from "../../components/EventDetails";
import { EventDetailsProps } from "../../components/EventDetails/EventDetails";
import { eventList } from "../../mocks/mock_events";

import "./EventDetailsContainer.scss";

interface EventDetailsContainerProps {}

const EventDetailsContainer: React.FC<EventDetailsContainerProps> = () => {
  const { eventId } = useParams<{ eventId: string }>();

  const fetchEvent = () => {
    if (eventId) {
      return eventList.filter((e) => e.id === eventId)[0];
    }
  };

  const event: EventDetailsProps | undefined = fetchEvent();

  return event ? (
    <EventDetails
      id={event.id}
      coverImage={event.coverImage}
      date={event.date}
      name={event.name}
      type={event.type}
      venues={event.venues}
      backCover={event.backCover}
      carouselImages={event.carouselImages}
      waConfirmation={event.waConfirmation}
      closing={event.closing}
      dressCode={event.dressCode}
      giftList={event.giftList}
      audio={event.audio}
    />
  ) : null;
};

export default EventDetailsContainer;
