import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";

import "./WAConfirmation.scss";

export interface WAConfirmationProps {
  instructions: string;
  message: string;
  note: string;
  receiver: string;
  title: string;
  waNumber: number;
}

export const WAConfirmation: React.FC<WAConfirmationProps> = ({
  instructions,
  message,
  note,
  receiver,
  title,
  waNumber,
}) => {
  const [name, setName] = useState<string>("");

  const sendWhatsAppMessage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let whatsAppMessage = message;
    if (name) {
      whatsAppMessage += ". Atte " + name;
    }
    const whatsAppLink = `https://wa.me/${waNumber}/?text=${encodeURI(
      whatsAppMessage
    )} `;
    const newWindow = window.open(
      whatsAppLink,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  return (
    <div className="WAConfirmation_component">
      <h2>{title}</h2>
      <div className="WAConfirmation_component__instructions">
        {instructions}
      </div>
      <div className="WAConfirmation_component__note">{note}</div>
      <form onSubmit={sendWhatsAppMessage}>
        <div className="WAConfirmation_component__form--name">
          <label>Nombre: </label>
          <input
            type="text"
            name="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <button type="submit" className="WAConfirmation_component__link">
          <FaWhatsapp /> <span>Confirmar asistencia con {receiver} </span>
        </button>
      </form>
    </div>
  );
};
