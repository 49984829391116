import React, { useState, useEffect } from "react";
import { FaPlayCircle, FaStopCircle } from "react-icons/fa";

import "./AudioPlayer.scss";

export interface AudioPlayerProps {
  url: string;
}

export const AudioPlayer: React.FC<AudioPlayerProps> = ({ url }) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => {
    setPlaying(!playing);
  };

  const initPlaying = () => {
    audio.autoplay = true;
    audio.muted = true;
    setPlaying(true);
  };

  useEffect(
    () => {
      if (playing) {
        audio.muted = false;
        var promise = audio.play();
        if (promise !== undefined) {
          promise
            .then((_) => {
              // Autoplay started!
            })
            .catch((_) => {
              setPlaying(false);
            });
        }
      } else {
        audio.pause();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [playing]
  );

  useEffect(() => {
    audio.addEventListener("canplaythrough", () => {
      initPlaying();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="audioPlayer__component">
      <button className="audioPlayer__component_button" onClick={toggle}>
        {playing ? <FaStopCircle /> : <FaPlayCircle />}
      </button>
    </div>
  );
};
